/* eslint-disable multiline-ternary */
import _, { isEmpty } from 'lodash';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { Key } from 'react';
import { KEY_ADS_POSITION } from 'src/components/ads/dynamicAds';
import SectionElection from 'src/components/sections/section-events/section-election2566';
import { IAds } from 'src/modules/ads';
import { DataSets } from 'src/modules/data-set/dataSet';
import { Data, Datum } from 'src/modules/sections/section-1';
import { DATALAYER_TYPE, sendDataLayerGlobal } from 'src/utils/datalayer/send-datalayer';
import { useDesktop, useMobilePhone, useTabletPc } from 'src/utils/device';
import { convertValue, dropSomeField } from 'src/utils/helper';
import styled from 'styled-components';
import SectionEvent from '../section-events/events-012/section-event';
import SectionDiversity from '../section-events/section-diversity';
import SectionPrideCampaign from '../section-events/section-pride-campaign';

const CardH = dynamic(import('src/components/cards/card-h'));
const CardOverlay = dynamic(import('src/components/cards/card-overlay'));
const CardV = dynamic(import('src/components/cards/card-v'));
const CardHLG = dynamic(import('src/components/cards/card-h-lg'));
const Carousels = dynamic(import('src/components/carousels'));
const DynamicAds = dynamic(import('src/components/ads/dynamicAds'));
const SectionMember = dynamic(import('src/services/member-auth/components/section-member'));
const SectionHighlight = dynamic(() => import('src/components/sections/section-highlight'), { ssr: true });
const MenuNavbar = dynamic(import('src/components/widgets/menu-navbar'));
type Datas = {
  data: Data;
  dataSets: DataSets;
  highlightText?: any;
  acticlesresDataPin: any;
  dataSetPinEmbed: any;
  dataPopular: any;
  subMenu: any;
  navBar: any;
  ads: IAds[];
  sectionMember: any;
  sectionElection: any;
  dataSetSectionPride?: any;
  sectionPrideData?: any;
  sectionDiversity?: any;
};

const SectionOne = ({
  data,
  dataSets,
  highlightText,
  // acticlesresDataPin,
  // dataSetPinEmbed,
  dataPopular,
  // subMenu,
  navBar,
  ads,
  sectionMember,
  sectionElection,
  dataSetSectionPride,
  sectionPrideData,
  sectionDiversity
}: Datas) => {
  if (_.isEmpty(data)) return null;

  const router = useRouter();

  function ModifyDataset(_data: any = []) {
    const result = [];
    const _itemImg = convertValue(_data, 'images');
    const _itemTitle = convertValue(_data, 'title');
    const _itemLink = convertValue(_data, 'link');

    for (let i = 0; i < _itemImg.length; i++) {
      result.push({
        id: i + 1,
        image: _itemImg[i]?.value,
        title: _itemTitle[i]?.value,
        link: _itemLink[i]?.value
      });
    }
    return result;
  }
  // check ID
  // const articlesFilter = data?.block3?.data?.filter(
  //   (i) => i.id !== acticlesresDataPin?.id
  // );

  // slicID
  // const sliceId = !_.isEmpty(acticlesresDataPin) ? 0 : 1;

  const dataLockLengthStart = 4; // !_.isEmpty(data?.newHighlight2) ? 0 : 4;
  const dataLockLengtEnd = 8; // !_.isEmpty(data?.newHighlight2) ? 4 : 8;

  const lock = data?.newHighlight2;
  const news = data?.headerLatestNews;
  const _data = lock && news && [...news, ...lock];
  const resultDate = _data && lock ? _data.filter(el => lock.every(val => val.id !== el.id)) : news;

  const dataLockLengthStartIII = 4; //! _.isEmpty(data?.newHighlight3) ? 0 : 4;
  const dataLockLengtEndIII = 8; //! _.isEmpty(data?.newHighlight3) ? 4 : 8;

  const lockIII = data?.newHighlight3;
  const newsIII = data?.block3?.data;
  const _dataIII = lockIII && newsIII && [...lockIII, ...newsIII];
  const resultDateIII = _dataIII && lockIII ? _dataIII.filter(el => lockIII.every(val => val.id !== el.id)) : newsIII;
  // console.log('data?.newHighlight2', data);
  // console.log('resultDate', resultDate);

  const checkLockSectionBlock1 = () => (
    // <>
    //   {!_.isEmpty(data?.newHighlight2) ? (
    //     <>
    //       <Carousels slideShow={1} mobileSlideShow={1} pauseOnHover={false} autoplaySpeed={6000} infinite={true}>
    //         {data?.newHighlight2.map((item, index) => (
    //           <div key={index}>
    //             <CardOverlay
    //               data={item}
    //               showTitle={true}
    //               showBlurb={false}
    //               showByline={false}
    //               showTime={false}
    //               external={false}
    //               //! DATALAYER
    //               eventDataLayer={{
    //                 type: DATALAYER_TYPE.TRACK_INDEX,
    //                 router: router?.pathname,
    //                 section: 'section-2',
    //                 data: {
    //                   block: '1',
    //                   title: item?.title,
    //                   heading: data?.block1?.title,
    //                   index: '1'
    //                 }
    //               }}
    //             />
    //           </div>
    //         ))}
    //       </Carousels>
    //     </>
    //   ) : (
    <>
      {!_.isEmpty(data?.headerLatestNews) && (
        <Carousels slideShow={1} mobileSlideShow={1} pauseOnHover={false} autoplaySpeed={6000} infinite={true}>
          {data?.headerLatestNews?.slice(0, 4).map((item, index) => (
            <div key={index}>
              <CardOverlay
                data={item}
                showTitle={true}
                showBlurb={false}
                showByline={false}
                showTime={false}
                external={false}
                //! DATALAYER
                eventDataLayer={{
                  type: DATALAYER_TYPE.TRACK_INDEX,
                  router: router?.pathname,
                  section: 'section-2',
                  data: {
                    block: '1',
                    title: item?.title,
                    heading: data?.block1?.title,
                    index: '1'
                  }
                }}
              />
            </div>
          ))}
        </Carousels>
      )}
    </>
    //   )}
    // </>
  );

  const checkLockSectionBlock2 = () => (
    // <>
    //   {!_.isEmpty(data?.newHighlight3) ? (
    //     <>
    //       <Carousels slideShow={1} mobileSlideShow={1} pauseOnHover={false} autoplaySpeed={10000} infinite={true}>
    //         {data?.newHighlight3.map((item, index) => (
    //           <div className='crad-audio-category-item' key={index}>
    //             <CardHLG
    //               data={item}
    //               showTitle={true}
    //               showBlurb={true}
    //               showByline={false}
    //               showTime={false}
    //               external={false}
    //               showCountview={false}
    //               //! DATALAYER
    //               eventDataLayer={{
    //                 type: DATALAYER_TYPE.TRACK_INDEX,
    //                 router: router?.pathname,
    //                 section: 'section-5',
    //                 data: {
    //                   block: '1',
    //                   title: item?.title,
    //                   heading: data?.block3?.title,
    //                   index: '1'
    //                 }
    //               }}
    //             />
    //           </div>
    //         ))}
    //       </Carousels>
    //     </>
    //   ) : (
    <>
      {!_.isEmpty(data?.block3) && (
        <Carousels slideShow={1} mobileSlideShow={1} pauseOnHover={false} autoplaySpeed={10000} infinite={true}>
          {data?.block3?.data?.slice(0, 4).map((item, index) => (
            <div className='crad-audio-category-item' key={index}>
              <CardHLG
                data={item}
                showTitle={true}
                showBlurb={true}
                showByline={false}
                showTime={false}
                external={false}
                showCountview={false}
                //! DATALAYER
                eventDataLayer={{
                  type: DATALAYER_TYPE.TRACK_INDEX,
                  router: router?.pathname,
                  section: 'section-5',
                  data: {
                    title: item?.title,
                    heading: data?.block3?.title,
                    index: 1
                  }
                }}
              />
            </div>
          ))}
        </Carousels>
      )}
    </>
    //   )}
    // </>
  );

  return (
    <>
      <SectionWrapper>
        {/* Start Block 1 */}
        <MenuNavbar
          _data={navBar}
          eventDataLayer={{
            type: DATALAYER_TYPE.TRACK_POSITION,
            router: router?.pathname,
            section: 'section-1',
            position: 'ใต้:header'
          }}
        />
        <div className='container'>
          <SectionHighlight data={data?.newHighlight} unsetBottom={true} ads={ads} highlightText={highlightText} />
        </div>
        <SectionDiversity data={sectionDiversity} hightligth={sectionPrideData?.sectionTwo} />
        {(!_.isEmpty(dataSetSectionPride?.sectionOne) && !_.isEmpty(sectionPrideData?.sectionOne)) ||
          (!_.isEmpty(dataSetSectionPride?.sectionTwo) && !_.isEmpty(sectionPrideData?.sectionTwo)) ||
          (!_.isEmpty(dataSetSectionPride?.sectionThree) && !_.isEmpty(sectionPrideData?.sectionThree)) ||
          !_.isEmpty(dataSetSectionPride?.sectionFour) ? (
          <SectionPrideCampaign dataSetSectionPride={dataSetSectionPride} sectionPrideData={sectionPrideData} />
        ) : null}
        <div className='container'>
          <SectionEvent />

          {/* section election 2566 */}
          <SectionElection data={sectionElection} />

          {!_.isEmpty(data?.headerLatestNews) && (
            <>
              <div className='row block-one'>
                <div className='col-12 col-md-8'>
                  <div className='title-left'>
                    <a href={'/category/latest-news'} aria-label={data.block1.title} title={data.block1.title}>
                      <h2>{data.block1.title}</h2>
                    </a>
                    <a href={'/category/latest-news'} aria-label='ข่าวล่าสุด' title='ข่าวล่าสุด'>
                      <p>ดูทั้งหมด</p>
                    </a>
                  </div>
                  {checkLockSectionBlock1()}
                </div>
                <div className='col-12 col-md-4 most-view-desktop'>
                  {!_.isEmpty(dataPopular) && (
                    <>
                      <div className='title-right'>
                        <h2>
                          most <strong>views</strong>
                        </h2>
                      </div>
                      <div className='side-right'>
                        {dataPopular?.slice(0, 8).map((item: Datum, i: Key | any) => (
                          <div key={i}>
                            <CardH
                              data={item}
                              no={i}
                              showTitle={true}
                              showBlurb={false}
                              showByline={false}
                              showTime={false}
                              showCountview={false}
                              external={false}
                              showImage={false}
                              //! DATALAYER
                              eventDataLayer={{
                                type: DATALAYER_TYPE.TRACK_INDEX,
                                router: router?.pathname,
                                section: 'section-2',
                                data: {
                                  block: '2',
                                  title: item?.title,
                                  heading: 'most views',
                                  index: i + 2
                                }
                              }}
                            />
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className='row card-list'>
                {resultDate.slice(dataLockLengthStart, dataLockLengtEnd).map((item, i) => (
                  <div key={i} className='col-12 col-md-3 card-items'>
                    <CardV
                      data={item}
                      showTitle={true}
                      showBlurb={false}
                      showByline={false}
                      showTime={false}
                      showCountview={false}
                      external={false}
                      //! DATALAYER
                      eventDataLayer={{
                        type: DATALAYER_TYPE.TRACK_INDEX,
                        router: router?.pathname,
                        section: 'section-2',
                        data: {
                          block: '1',
                          title: item?.title,
                          heading: data?.block1?.title,
                          index: i + 2
                        }
                      }}
                    />
                  </div>
                ))}
              </div>
              <div className='border-bottom' />
            </>
          )}
        </div>
        {/* End Block 1 */}
        {/* Start Block 2 */}
        {/* ----------------------------- SECTION_MEMBER -----------------------------  */}
        {!isEmpty(sectionMember) && (
          <SectionMember
            data={{
              user: dropSomeField(sectionMember, ['menu', 'tags', 'articlesTags', 'articlesCategories'])?.user,
              menu: sectionMember?.menu,
              tags: sectionMember?.tags,
              articlesTags: sectionMember?.articlesTags,
              articlesCategories: sectionMember?.articlesCategories
            }}
          />
        )}
        <div id='section-3'>
          {!_.isEmpty(data?.block2?.data) && (
            <>
              <div suppressHydrationWarning={true}>
                {!useMobilePhone() && (
                  <div className='container'>
                    <div className='block-two'>
                      <div className='title-left'>
                        <a href={data?.block2?.link} aria-label={data?.block2?.title} title={data?.block2?.title}>
                          <h2>{data?.block2?.title}</h2>
                        </a>
                        <a href={data?.block2?.link} aria-label={data?.block2?.title} title={data?.block2?.title}>
                          <p>ดูทั้งหมด</p>
                        </a>
                      </div>
                      <div className='row'>
                        <div className='card-items col-5'>
                          <CardV
                            data={data?.block2?.data[0]}
                            showTitle={true}
                            showBlurb={false}
                            showByline={false}
                            showTime={false}
                            showCountview={false}
                            external={false}
                            //! DATALAYER
                            eventDataLayer={{
                              type: DATALAYER_TYPE.TRACK_INDEX,
                              router: router?.pathname,
                              section: 'section-3',
                              data: {
                                title: data?.block2?.data[0]?.title,
                                heading: data?.block2?.title,
                                index: 1
                              }
                            }}
                          />
                        </div>
                        <div className='col-7'>
                          <div className='row card-list'>
                            {data?.block2?.data?.slice(1, 7).map((item, i) => (
                              <div key={i} className='card-items col-4'>
                                <CardV
                                  data={item}
                                  showTitle={true}
                                  showBlurb={false}
                                  showByline={false}
                                  showTime={false}
                                  showCountview={false}
                                  external={false}
                                  //! DATALAYER
                                  eventDataLayer={{
                                    type: DATALAYER_TYPE.TRACK_INDEX,
                                    router: router?.pathname,
                                    section: 'section-3',
                                    data: {
                                      title: item?.title,
                                      heading: data?.block2?.title,
                                      index: i + 2
                                    }
                                  }}
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div suppressHydrationWarning={true}>
                {useMobilePhone() && (
                  <div className='block-2'>
                    <div className='container'>
                      <div className='title-left'>
                        <a href={data?.block2?.link} aria-label={data?.block2?.title} title={data?.block2?.title}>
                          <h2>{data?.block2?.title}</h2>
                        </a>
                        <a href={data?.block2?.link} aria-label={data?.block2?.title} title={data?.block2?.title}>
                          <p>ดูทั้งหมด</p>
                        </a>
                      </div>
                    </div>
                    <Carousels slideShow={3} mobileSlideShow={2} pauseOnHover={false} autoplaySpeed={6000} center={true} infinite={true}>
                      {data?.block2?.data?.slice(0, 6).map((item, i) => (
                        <div key={i} className='col-4 card-items'>
                          <CardV
                            data={item}
                            showTitle={true}
                            showBlurb={false}
                            showByline={false}
                            showTime={false}
                            showCountview={false}
                            external={false}
                            //! DATALAYER
                            eventDataLayer={{
                              type: DATALAYER_TYPE.TRACK_INDEX,
                              router: router?.pathname,
                              section: 'section-3',
                              data: {
                                title: item?.title,
                                heading: data?.block2?.title,
                                carousel: i + 2
                              }
                            }}
                          />
                        </div>
                      ))}
                    </Carousels>

                    <div className='col-12 col-md-4 container most-view-mobile'>
                      {!_.isEmpty(dataPopular) && (
                        <>
                          <div className='title-right'>
                            <h2>
                              most <strong>views</strong>
                            </h2>
                          </div>
                          <div className='side-right'>
                            {dataPopular?.slice(0, 8).map((item: Datum, i: Key | any) => (
                              <div key={i}>
                                <CardH
                                  data={item}
                                  no={i}
                                  showTitle={true}
                                  showBlurb={false}
                                  showByline={false}
                                  showTime={false}
                                  showCountview={false}
                                  external={false}
                                  showImage={false}
                                  //! DATALAYER
                                  eventDataLayer={{
                                    type: DATALAYER_TYPE.TRACK_INDEX,
                                    router: router?.pathname,
                                    section: 'section-3',
                                    data: {
                                      block: '2',
                                      title: item?.title,
                                      heading: 'most views',
                                      index: i + 2
                                    }
                                  }}
                                />
                              </div>
                            ))}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
        {/* Ads-PPN INARTICLE-1 */}
        {!_.isEmpty(ads) && <DynamicAds position={KEY_ADS_POSITION.INARTICLE_1} data={ads} />}
        {/* Ads-PPN INARTICLE-2 */}
        {!_.isEmpty(ads) && <DynamicAds position={KEY_ADS_POSITION.INARTICLE_2} data={ads} />}
        {/* End Block 2 */}
        {/* Start Event 0 */}
        {!_.isEmpty(dataSets?.dataSet) && (
          <EventWrapper id='section-4' className='section-event'>
            <div className='container'>
              <Carousels slideShow={1} pauseOnHover={true} autoplaySpeed={8000}>
                {ModifyDataset(dataSets?.dataSet)?.map((item, i) => (
                  <a
                    key={i}
                    className='event-a'
                    aria-hidden='true'
                    href={`${item?.link || '#'}`}
                    //! DATALAYER
                    onClick={() =>
                      sendDataLayerGlobal({
                        type: DATALAYER_TYPE.TRACK_INDEX,
                        router: router?.pathname,
                        section: 'section-4',
                        data: {
                          title: item?.title || item?.link,
                          heading: 'image',
                          carousel: i + 1
                        }
                      })
                    }
                  >
                    <div className='event-0'>
                      <img src={item?.image} alt={item?.title || 'event'} title={item?.title || 'event'} width='100%' height='100%' aria-hidden='true' loading='lazy' />
                      <div className='event-href'>
                        <h3>{item?.title}</h3>
                      </div>
                    </div>
                  </a>
                ))}
              </Carousels>
            </div>
          </EventWrapper>
        )}
        {/* End Event 0 */}
        {/* Start Block 3 */}
        {!_.isEmpty(resultDateIII) && (
          <div className='block-three' id='section-5'>
            <div className='container'>
              <>
                <div className='title-left'>
                  <a href={data?.block3?.link} aria-label={data?.block3?.title} title={data?.block3?.title}>
                    <h2>{data?.block3?.title}</h2>
                  </a>
                  <a href={data?.block3?.link} aria-label={data?.block3?.title} title={data?.block3?.title}>
                    <p>ดูทั้งหมด</p>
                  </a>
                </div>
                {checkLockSectionBlock2()}
              </>
              {!_.isEmpty(resultDateIII) && (
                <div className='row card-list'>
                  {useMobilePhone() && (
                    <Carousels mobileSlideShowTwo={2} pauseOnHover={false} autoplaySpeed={6000} arrows={resultDateIII && true}>
                      {resultDateIII.slice(dataLockLengthStartIII, dataLockLengtEndIII).map((item, i) => (
                        <div key={i} className='card-items'>
                          <CardV
                            data={item}
                            showTitle={true}
                            showBlurb={false}
                            showByline={false}
                            showTime={false}
                            showCountview={false}
                            external={false}
                            //! DATALAYER
                            eventDataLayer={{
                              type: DATALAYER_TYPE.TRACK_INDEX,
                              router: router?.pathname,
                              section: 'section-5',
                              data: {
                                title: item?.title,
                                heading: data?.block3?.title,
                                carousel: i + 1
                              }
                            }}
                          />
                        </div>
                      ))}
                    </Carousels>
                  )}
                  {useTabletPc() && (
                    <>
                      {resultDateIII.slice(dataLockLengthStartIII, dataLockLengtEndIII).map((item, i) => (
                        <div key={i} className='col-md-3 card-items'>
                          <CardV
                            data={item}
                            showTitle={true}
                            showBlurb={false}
                            showByline={false}
                            showTime={false}
                            showCountview={false}
                            external={false}
                            //! DATALAYER
                            eventDataLayer={{
                              type: DATALAYER_TYPE.TRACK_INDEX,
                              router: router?.pathname,
                              section: 'section-5',
                              data: {
                                title: item?.title,
                                heading: data?.block3?.title,
                                index: i + 2
                              }
                            }}
                          />
                        </div>
                      ))}
                    </>
                  )}
                  {useDesktop() && (
                    <>
                      {resultDateIII.slice(dataLockLengthStartIII, dataLockLengtEndIII).map((item, i) => (
                        <div key={i} className='col-md-3 card-items'>
                          <CardV
                            data={item}
                            showTitle={true}
                            showBlurb={false}
                            showByline={false}
                            showTime={false}
                            showCountview={false}
                            external={false}
                            //! DATALAYER
                            eventDataLayer={{
                              type: DATALAYER_TYPE.TRACK_INDEX,
                              router: router?.pathname,
                              section: 'section-5',
                              data: {
                                title: item?.title,
                                heading: data?.block3?.title,
                                index: i + 2
                              }
                            }}
                          />
                        </div>
                      ))}
                    </>
                  )}
                </div>
              )}
              <div className='border-bottom' />
            </div>
          </div>
        )}
        {/* End Block 3 */}
      </SectionWrapper>
    </>
  );
};

const SectionWrapper = styled.section`
  .submenu {
    min-height: 121px;
    @media (max-width: 768px) {
      min-height: 85px;
    }
    @media (max-width: 475px) {
      min-height: 70px;
    }
    .submenu-list {
      list-style-type: none;
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      margin: 0;
      width: 100%;
      text-align: center;
      li {
        width: 20%;
        max-width: 100%;
        @media (max-width: 480px) {
          width: unset;
          max-width: unset;
          padding: 0 10px;
        }
        p {
          width: fit-content;
          font-size: 24px;
          text-transform: uppercase;
          font-weight: 400;
          color: #b3b3b3;
          padding: 10px 0 2px 0;
          margin: auto;
          border-bottom: 2px solid transparent;
          @media (max-width: 768px) {
            font-size: 18px;
          }
          @media (max-width: 480px) {
            font-size: 12px;
          }
          &:hover {
            border-bottom: 2px solid #ed1a3b;
            color: #000 !important;
          }
          &.--active {
            border-bottom: 2px solid #ed1a3b;
            color: #000 !important;
          }
        }
      }
    }
    .border-bottom {
      margin-top: 30px;
      margin-right: 15px;
      margin-left: 15px;
      @media (max-width: 1024px) {
        margin-top: 10px;
        margin-right: unset;
        margin-left: unset;
      }
    }
    @media (max-width: 480px) {
      margin-top: -20px;
    }
  }

  .block-2 {
    padding-bottom: 30px;
    .slick-list {
      padding: 0 20px 20px 20px !important;
    }
    .slick-dots {
      bottom: 0;
    }

    .most-view-mobile {
      display: none;
    }

    @media (max-width: 480px) {
      .most-view-mobile {
        display: block;
        .side-right {
          overflow-y: scroll;
          height: 300px;
          &::-webkit-scrollbar {
            width: 4px;
            background-color: #f5f5f5;
          }

          &::-webkit-scrollbar-track {
            background-color: rgba(247, 247, 247, 0.9);
          }

          &::-webkit-scrollbar-thumb {
            border-radius: 50px;
            background-color: rgb(220 21 53);
          }
        }
      }
    }

    @media (max-width: 690px) {
      .title-left {
        margin-top: 30px;
      }
    }
  }
  .title-left {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 768px) {
      margin-top: 15px;
    }
    a {
      transition: all 0.4s ease-in-out;
      &:hover {
        color: #ed1a3b;
      }
    }
    h2 {
      text-transform: uppercase;
      font-size: 46px;
      font-weight: bold;
      @media (max-width: 768px) {
        font-size: 26px;
      }
    }
    p {
      padding: 2px 20px;
      background-color: rgb(220 21 53);
      color: #fff;
      border-radius: 8px;
      &:hover {
        color: #ed1a3b;
        background-color: rgb(255, 255, 255);
      }
    }
  }
  .title-right {
    padding-left: 6px;
    text-transform: capitalize;
    margin: 5px 0;
    @media (max-width: 768px) {
      margin: 20px 0 0 0;
    }
    h2 {
      font-size: 32px;
      font-weight: 400;
      @media (max-width: 768px) {
        font-size: 26px;
      }
      strong {
        color: #ed1a3b;
      }
    }
  }
  .card-items {
    padding-left: 10px;
    padding-right: 10px;
  }
  .border-bottom {
    border-bottom: 1px solid #80808042;
  }
  .card-list {
    padding-bottom: 30px;
    font-weight: bold;
    .card {
      h3 {
        font-size: 16px;
      }
    }
  }

  /* block-one */
  .block-one {
    padding: 26px 0 0;
    @media (max-width: 690px) {
      .card-overlay {
        .card {
          h3 {
            width: 90%;
          }
        }
        .play-btn-video {
          bottom: 15px !important;
        }
      }
      /* padding: 0; */
    }
    .slick-list {
      padding: 0 0 35px 0;
    }
    .slick-dots {
      bottom: 14px;
    }
    .most-view-desktop {
      display: block;
      .side-right {
        overflow-y: scroll;
        height: 300px;
        &::-webkit-scrollbar {
          width: 4px;
          background-color: #f5f5f5;
        }

        &::-webkit-scrollbar-track {
          background-color: rgba(247, 247, 247, 0.9);
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 50px;
          background-color: rgb(220 21 53);
        }
      }
    }
    .title-left {
      @media (max-width: 768px) {
        margin-top: 0;
      }
    }
    @media (max-width: 480px) {
      flex-direction: column;
      .most-view-desktop {
        display: none;
      }
    }
  }

  /* block-two */
  .block-two {
    padding: 60px 0 0 0;
    .card-list {
      .card {
        h3 {
          font-size: 16px;
        }
      }
    }
    /* .card-items {
      padding-left: 10px;
      padding-right: 10px;
    } */
  }
  .section-event {
    .container {
      a {
        display: inline-grid !important;
      }
      @media (max-width: 1024px) {
        padding: 0;
        .carousel {
          padding: 0;
        }
      }
    }
    .slick-list {
      padding: 0 0 0 0;
    }
    .slick-slide > div {
      display: flex;
    }
  }
  /* block-three */
  .block-three {
    background: #1a1a1a;
    padding: 60px 0 30px 0;
    .slick-list {
      padding: 0 0 35px 0;
    }
    .slick-dots {
      bottom: 14px;
    }
    @media (max-width: 690px) {
      padding: 30px 0 0 0;
      .title-left {
        margin-top: 0;
      }
    }
    .card {
      h3 {
        font-size: 16px;
      }
    }
    .title-left {
      a {
        color: rgb(220 21 53);
        transition: all 0.4s ease-in-out;
        &:hover {
          color: #fff;
        }
      }
    }
    .card-items {
      .card {
        &.--content {
          h3 {
            color: #fff;
          }
        }
      }
    }
  }
`;

const EventWrapper = styled.div`
  background: #000000;
  .event-0 {
    position: relative;
    .event-href {
      position: absolute;
      top: 50%;
      left: 10%;
      transform: translate(-20%, -50%);
    }
  }

  .event-a {
    color: #fff;
    h3 {
      font-size: 32px;
      width: 50%;
      margin: auto;
      text-align: center;
      @media (max-width: 480px) {
        font-size: 18px;
      }
    }
  }
`;

export default SectionOne;
